'use client';

import { TooltipProvider } from '@/components/ui';
import { nhost } from '@/lib/nhost';
import { TRPCReactProvider } from '@/lib/trpc/client';
import AuthProvider from '@/providers/auth-provider';
import { PostHogProvider } from '@/providers/posthog-provider';
import { NhostProvider } from '@nhost/nextjs';
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  return <NhostProvider nhost={nhost} data-sentry-element="NhostProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <TRPCReactProvider data-sentry-element="TRPCReactProvider" data-sentry-source-file="providers.tsx">
        <PostHogProvider data-sentry-element="PostHogProvider" data-sentry-source-file="providers.tsx">
          <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-source-file="providers.tsx">
            <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="providers.tsx">{children}</AuthProvider>
          </TooltipProvider>
        </PostHogProvider>
      </TRPCReactProvider>
    </NhostProvider>;
}