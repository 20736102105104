import { NhostClient } from '@nhost/nextjs';
import { env } from './env';

export const nhost = new NhostClient({
  authUrl: env.NEXT_PUBLIC_NHOST_AUTH_URL,
  graphqlUrl: env.NEXT_PUBLIC_NHOST_GRAPHQL_URL,
  autoRefreshToken: true,
  storageUrl: 'https://storage.nowhere',
  functionsUrl: 'https://functions.nowhere',
});
