'use client';

import { env } from '@/lib/env';
import posthog from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';
if (typeof window !== 'undefined') {
  const isDebug = env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true';
  if (isDebug) {
    posthog.debug(true);
  }
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: '/ingest',
    ui_host: 'https://app.posthog.com',
    // person_profiles: "identified_only",
    capture_pageview: false,
    // Disable automatic pageview capture, as we capture manually
    disable_session_recording: isDebug
  });
}
export function PostHogProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <Provider client={posthog} data-sentry-element="Provider" data-sentry-component="PostHogProvider" data-sentry-source-file="posthog-provider.tsx">{children}</Provider>;
}