import { AuthContext } from '@/contexts/auth-context';
import useAuth from '@/hooks/use-auth';
import { FC, ReactNode } from 'react';
const AuthProvider: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const {
    isAuthenticated,
    isLoading,
    isError,
    error
  } = useAuth();
  return <AuthContext.Provider value={{
    isAuthenticated,
    isLoading,
    isError,
    error
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="auth-provider.tsx">
      {children}
    </AuthContext.Provider>;
};
export default AuthProvider;