import { atomWithStorage } from 'jotai/utils';
interface BannerDismissals {
  [bannerId: string]: string | null;
}

/**
 * Atom to track all banner dismissals
 * Stores a mapping of banner IDs to dismissal timestamps
 * Persists in localStorage to maintain state across sessions
 */
export const bannerDismissalsAtom = atomWithStorage<BannerDismissals>('bannerDismissals', {});
export const attemptedJobOverMaxDurationLimitAtom = atomWithStorage('attemptedJobOverMaxDurationLimit', false);
export const activeOrganizationIdAtom = atomWithStorage<string | null>('activeOrganizationId', null);