import { useAccessToken } from '@nhost/nextjs';
import { AppRouter } from '@sync/api/src/modules/trpc/trpc.router';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TRPCClientError, loggerLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { useMemo } from 'react';
import superjson from 'superjson';
import { activeOrganizationIdAtom } from '@/app/(protected)/atoms';
import { env } from '@/lib/env';
import { useAtom } from 'jotai';
import { toast } from 'sonner';
const STALE_TIME = 30 * 1000; // 30 seconds
const MAX_RETRY_ATTEMPTS = 3;
const createQueryClient = () => new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: unknown) => {
        if (error instanceof TRPCClientError) {
          if (error?.data?.code === 'TOO_MANY_REQUESTS') return false;
        }
        return failureCount < MAX_RETRY_ATTEMPTS;
      },
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: STALE_TIME
    }
  },
  queryCache: new QueryCache({
    onError: (err: unknown) => {
      if (err instanceof TRPCClientError) {
        if (err?.data?.code === 'TOO_MANY_REQUESTS') toast.error("cool down, you're doing too many requests!");
      }
    }
  })
});
let clientQueryClientSingleton: QueryClient | undefined = undefined;
const getQueryClient = () => {
  if (typeof window === 'undefined') {
    // Server: always make a new query client
    return createQueryClient();
  } else {
    // Browser: use singleton pattern to keep the same query client
    return clientQueryClientSingleton ??= createQueryClient();
  }
};
export const api = createTRPCReact<AppRouter>();
export function TRPCReactProvider(props: {
  children: React.ReactNode;
}) {
  const queryClient = getQueryClient();
  const accessToken = useAccessToken();
  const [activeOrganizationId] = useAtom(activeOrganizationIdAtom);
  const trpcClient = useMemo(() => api.createClient({
    links: [loggerLink({
      enabled: op => process.env.NODE_ENV === 'development' || op.direction === 'down' && op.result instanceof Error
    }), unstable_httpBatchStreamLink({
      url: `${env.NEXT_PUBLIC_API_BASE_URL}/trpc`,
      headers: async () => ({
        ...(accessToken ? {
          Authorization: `Bearer ${accessToken}`,
          'x-sync-source': 'web',
          ...(activeOrganizationId ? {
            'x-org-id': activeOrganizationId
          } : {})
        } : {})
      }),
      transformer: superjson
    })]
  }), [accessToken, activeOrganizationId]);
  return <api.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-component="TRPCReactProvider" data-sentry-source-file="client.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="client.tsx">
        {props.children}
      </QueryClientProvider>
    </api.Provider>;
}