import { useAuthenticationStatus } from '@nhost/nextjs';
import { usePathname, useRouter } from 'next/navigation';
// utils/useAuth.tsx
import { useEffect } from 'react';

const PUBLIC_ROUTES = ['/share'];

const useAuth = () => {
  const { isAuthenticated, isLoading, isError, error } =
    useAuthenticationStatus();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (isLoading) {
      return; // Skip redirection if still loading
    }

    const isPublicRoute = PUBLIC_ROUTES.some((route) =>
      pathname.startsWith(route),
    );

    if (!isAuthenticated && !isPublicRoute) {
      router.push('/login');
    }
  }, [isAuthenticated, isLoading, router]);

  return { isAuthenticated, isLoading, isError, error };
};

export default useAuth;
